import * as CONST from '../mutations/Configuration'
import ConfigurationService from '../../services/Configuration'
const state = {
  customFields: [],
  customFieldById: [],
  errors: [],
}

const getters = {
  getCustomFields: state => state.customFields || [],
  getCustomFieldById: state => state.customFieldById || [],
  getErrors: state => state.errors || [],
}

const mutations = {
  [CONST.SET_ALL_CUSTOM_FIELDS]: (state, customFields) => {
    state.customFields = customFields
  },
  [CONST.SET_CUSTOM_FIELD_BY_ID]: (state, customField) => {
    state.customFieldById = customField
  },
  [CONST.SET_CUSTOM_FIELD_ERRORS]: (state, errors = []) => {
    state.errors = errors
  },
}

const actions = {
  async fetchAllCustomFields({ commit }) {
    let { type, data } = await ConfigurationService.getAllCustomFields()
    if (type === 'success') {
      commit(CONST.SET_ALL_CUSTOM_FIELDS, data)
    } else {
      commit(CONST.SET_CUSTOM_FIELD_ERRORS, data)
    }
  },
  async fetchCustomFieldByID({ commit }, id) {
    if (!id) {
      return
    }
    const response = await ConfigurationService.getCustomFieldById(id)

    if (response.type == 'success') {
      commit(CONST.SET_CUSTOM_FIELD_BY_ID, response.data)
    }

    if (response.type == 'error') {
      commit(CONST.SET_CUSTOM_FIELD_ERRORS, response.data)
    }
  },
  async createCustomField({ commit }, data) {
    if (!data) {
      throw new Error('Custom Field Data is Required')
    }

    const response = await ConfigurationService.createCustomField(data)

    if (response.type == 'error') {
      commit(CONST.SET_CUSTOM_FIELD_ERRORS, response.data)
    }
  },
  async updateCustomField({ commit }, data) {
    if (!data) {
      throw new Error('Custom Field Data is Required')
    }

    const response = await ConfigurationService.updateCustomField(data)

    if (response.type == 'error') {
      commit(CONST.SET_CUSTOM_FIELD_ERRORS, response.data)
    }
  },
  async deleteCustomField({ commit }, id) {
    if (!id) {
      throw new Error('Custom Field ID is Required')
    }

    const response = await ConfigurationService.deleteCustomField(id)

    if (response.type == 'error') {
      commit(CONST.SET_CUSTOM_FIELD_ERRORS, response.data)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
