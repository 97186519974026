import * as CONST from '../mutations/Auth'
import { vm } from '../../main'
import {
  assetManager,
  workManager,
  userManager,
  orgAdmin,
  token,
  inOrg,
} from '@/constants'

const getDefaultState = () => ({
  token: '',
  user: null,
  errors: [],
})

const state = getDefaultState()
const getters = {
  isAuthenticated: state =>
    (state.token || !!localStorage.getItem(token)) &&
    !!localStorage.getItem(inOrg),
  getUser: state => state.user || {},
  getUserRoles: state => vm.$tryGet(() => state.user.roles) || [],
  isAdmin: (state, getters) => getters.getUserRoles.includes(orgAdmin) || false,
  isAdminOrUserManager: (state, getters) =>
    getters.isAdmin || getters.getUserRoles.includes(userManager) || false,
  isAdminOrWorkManager: (state, getters) =>
    getters.isAdmin || getters.getUserRoles.includes(workManager) || false,
  isAdminOrAssetManager: (state, getters) =>
    getters.isAdmin || getters.getUserRoles.includes(assetManager) || false,
}
const mutations = {
  [CONST.RESET_AUTH_STATE]: state => {
    Object.assign(state, getDefaultState())
    localStorage.clear()
  },
  [CONST.SET_USER_ROLES]: (state, roles) => {
    if (!state.user) state.user = {}
    state.user.roles = roles
  },
  [CONST.SET_TOKEN]: (state, data) => {
    state.token = data.token
    localStorage.setItem(token, data.token)
    localStorage.setItem(inOrg, data.in_org)
  },
  [CONST.SET_USER]: (state, user) => {
    state.user = user
  },
  [CONST.SET_AUTH_ERRORS]: (state, errors) => {
    state.errors = errors
  },
}
const actions = {
  registerUser(vuexContext, { payload, query }) {
    return this._vm.$axios
      .post(`/auth/createbasic?org_id=${query}`, payload)
      .then(({ data }) => {
        if (data && data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.reject(data)
        }
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  login({ commit, dispatch }, payload) {
    return this._vm.$axios
      .post('/auth/authenticate', payload)
      .then(async ({ data }) => {
        const response = data.result && data.result[0]
        response.success = data.success
        if (data.success && response) {
          commit(CONST.SET_TOKEN, response)
          commit(CONST.SET_USER_ROLES, response.roles)
          if (response.in_org) {
            await dispatch('fetchCurrentUser')
            await dispatch('organization/fetchOrganizationSettings', null, {
              root: true,
            })
          }
          return Promise.resolve(response)
        } else {
          return Promise.reject(response)
        }
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  fetchCurrentUser({ commit }) {
    return this._vm.$axios
      .get('/users/me')
      .then(({ data }) => {
        const response = data.result && data.result[0]
        response.success = data.success
        if (data.success && response) {
          commit(CONST.SET_USER, response)
        } else {
          return Promise.reject(response)
        }
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  confirmEmail(vuexContext, payload) {
    return this._vm.$axios
      .post('/auth/confirmemail', payload)
      .then(result => {
        const response = result.data
        if (response.success) {
          return Promise.resolve(response)
        } else {
          return Promise.reject(response)
        }
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  sendPasswordResetEmail(vuexContext, payload) {
    return this._vm.$axios
      .post('/auth/beginpasswordreset', payload)
      .then(result => {
        const response = result.data
        if (response.success) {
          return Promise.resolve(response)
        } else {
          return Promise.reject(response)
        }
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  logout({ commit }) {
    commit(CONST.RESET_AUTH_STATE)
    commit('assets/RESET_ASSETS_STATE', null, {
      root: true,
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
