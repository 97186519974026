<template>
  <b-button
    :block="block"
    :variant="variant"
    :type="type"
    @click="$emit('click')"
    :disabled="disable"
  >
    <span class="d-flex align-items-center justify-content-center">
      <slot></slot>
      <b-spinner v-if="loading" class="mr-1" small></b-spinner>
      <i v-if="icon" :class="icon"></i>
      <span class="ml-1 d-block font-iphone">{{ title }}</span>
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    title: {
      type: String,
      default: 'Save',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
@media (max-width: 350px) {
  .font-iphone {
    font-size: 10px;
  }
}
</style>
