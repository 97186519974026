import ListTransformer from '../transformers/Lists'
import { instance as axios } from '../plugins/axios'

/**
 *
 * @param {*} type
 * @returns
 */
async function fetchItemsByType(type) {
  return axios
    .get(`/usage_items/by_item_type/${type}`)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse(
          'success',
          ListTransformer.transformListItemsArray(result)
        )
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

/**
 *
 * @param {*} id
 * @returns
 */
async function fetchItemByID(id) {
  return axios
    .get(`/usage_items/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse(
          'success',
          ListTransformer.transformListItemsArray(result)[0]
        )
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

/**
 *
 * @param {*} data
 * @returns
 */
async function createItem(data) {
  return axios
    .post(`/usage_items`, data)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result.usage_item_id)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}
/**
 *
 * @param {*} data
 * @returns
 */
async function updateItem(data) {
  return axios
    .put(`/usage_items/${data.id}`, data)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result.usage_item_id)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

/**
 *
 * @param {*} id
 * @returns
 */
async function deleteItem(id) {
  return axios
    .delete(`/usage_items/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result.success)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

export default {
  fetchItemsByType,
  fetchItemByID,
  createItem,
  deleteItem,
  updateItem,
}
