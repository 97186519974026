<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'app',
  metaInfo: {
    title: 'Index',
    titleTemplate: 'Asset Badger',
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('users', ['getUsers']),
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      fetchCurrentUser: 'auth/fetchCurrentUser',
      fetchUsers: 'users/fetchUsers',
      fetchOrganizationSettings: 'organization/fetchOrganizationSettings',
    }),
  },
  async created() {
    if (this.isAuthenticated) {
      await Promise.allSettled([
        this.fetchCurrentUser(),
        this.fetchOrganizationSettings(),
        this.fetchUsers(),
      ])
    } else {
      this.logout()
    }
  },
  updated() {
    // Remove loading state
    setTimeout(() => document.body.classList.remove('app-loading'), 1)
  },
}
</script>
