import { instance as axios } from '../plugins/axios'
import ListTransformer from '../transformers/Lists'

async function getAllTaskTypes() {
  return await axios
    .get('/task_types')
    .then(({ data }) => {
      const { success, error, result } = data
      if (success) {
        return ListTransformer.prepareResponse('success', result)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

async function getTaskTypeById(id) {
  return await axios
    .get(`/task_types/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data
      if (success) {
        return ListTransformer.prepareResponse('success', result[0])
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

async function createTaskType(data) {
  return axios
    .post(`/task_types`, data)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

async function updateTaskType(data) {
  return axios
    .put(`/task_types/${data.id}`, data.content)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

async function deleteTaskType(id) {
  return axios
    .delete(`/task_types/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}
export default {
  getAllTaskTypes,
  getTaskTypeById,
  createTaskType,
  updateTaskType,
  deleteTaskType,
}
