function transformListItem(data) {
  return {
    usageItemID: data.usage_item_id,
    userGroupID: data.user_group_id,
    itemType: data.item_type,
    name: data.name,
    units: data.units,
    unitRate: data.unit_rate,
  }
}

function transformListItemsArray(data) {
  return data.map(transformListItem)
}

function prepareResponse(messageType, data) {
  return {
    type: messageType,
    data: data,
  }
}

export default {
  transformListItem,
  transformListItemsArray,
  prepareResponse,
}
