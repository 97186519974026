<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar align-items-lg-center container-p-x"
  >
    <!-- Sidenav toggle -->
    <b-navbar-nav
      class="align-items-lg-center mr-auto mr-lg-4"
      v-if="sidenavToggle"
    >
      <a
        class="nav-item nav-link px-0 ml-2 ml-lg-0"
        href="javascript:void(0)"
        @click="toggleSidenav"
      >
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <!-- Brand -->
    <b-navbar-brand :to="{ name: 'assets' }" class="d-lg-none mr-0">
      <img src="/img/logo.png" width="60px" height="60px" alt="logo" />
    </b-navbar-brand>
    <!-- Navbar toggle -->
    <!-- <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle> -->
  </b-navbar>
</template>

<script>
export default {
  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg
    },
  },
}
</script>
