import { instance as axios } from '../plugins/axios'
import ListTransformer from '../transformers/Lists'

async function getAllWorkOrders() {
  return await axios
    .get(`/workorder_types`)
    .then(({ data }) => {
      const { success, error, result } = data
      if (success) {
        return ListTransformer.prepareResponse('success', result)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}
async function getWorkOrderById(id) {
  return await axios
    .get(`/workorder_types/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data
      if (success) {
        return ListTransformer.prepareResponse('success', result)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}
async function updateWorkOrder(data) {
  return axios
    .put(`/workorder_types/${data.pop()}`, data.pop())
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}
async function deleteWorkOrder(id) {
  return axios
    .delete(`/workorder_types/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result.success)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}
async function createWorkOrder(data) {
  return axios
    .post(`/workorder_types`, data)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse(
          'success',
          result.custom_field_id
        )
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

export default {
  getAllWorkOrders,
  getWorkOrderById,
  updateWorkOrder,
  deleteWorkOrder,
  createWorkOrder,
}
