import * as CONST from '../mutations/Maintenance'
import MaintenanceService from '../../services/Maintenance'
const state = {
  maintenanceTasks: [],
  maintenanceTask: {},
  meta: {},
  taskHistory: [],
  files: [],
  errors: [],
}

const getters = {
  maintenanceTasks: state => state.maintenanceTasks || [],
  maintenanceTask: state => state.maintenanceTask || {},
  taskHistory: state => state.taskHistory || [],
  getMaintenanceMeta: state => state.meta,
  getMaintenanceFiles: state => state.files,
  maintenanceHaveErrors: state => state.errors && state.errors.length > 0,
}

const mutations = {
  [CONST.SET_MAINTENANCE_TASKS]: (
    state,
    { result: tasks, loadMoreClicked }
  ) => {
    state.maintenanceTasks = loadMoreClicked
      ? [...state.maintenanceTasks, ...tasks]
      : [...tasks]
  },
  [CONST.SET_MAINTENANCE_TASK]: (state, data) => {
    state.maintenanceTask = data[0]
  },
  [CONST.RESET_MAINTENANCE_TASKS]: state => {
    state.maintenanceTasks = []
  },
  [CONST.SET_TASK_HISTORY]: (state, data) => {
    state.taskHistory = data
  },
  [CONST.SET_MAINTENANCE_ERRORS]: (state, errors = []) => {
    state.errors = errors
  },
  [CONST.SET_MAINTENANCE_META]: (state, meta) => {
    state.meta = meta
  },
  [CONST.SET_MAINTENANCE_FILES]: (state, files) => {
    state.files = files
  },
}

const actions = {
  fetchMaintenanceFiles({ commit }, id = '') {
    commit(CONST.SET_MAINTENANCE_ERRORS)
    if (!id) return
    return this._vm.$axios
      .get(`/tasks/${id}/files`)
      .then(({ data }) => {
        const { success, error, result } = data
        if (success) {
          commit(CONST.SET_MAINTENANCE_FILES, result)
        } else {
          commit(CONST.SET_MAINTENANCE_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_MAINTENANCE_ERRORS, error)
      })
  },
  deleteFileById({ commit, dispatch }, payload) {
    commit(CONST.SET_MAINTENANCE_ERRORS)
    if (!payload.id || !payload.fileId) {
      commit(
        CONST.SET_MAINTENANCE_ERRORS,
        'Maintenance Task id and File id are required fields'
      )
      return
    }
    return this._vm.$axios
      .delete(`/tasks/${payload.id}/files/${payload.fileId}`)
      .then(async ({ data }) => {
        const { success, error } = data
        if (!success) {
          commit(CONST.SET_MAINTENANCE_ERRORS, error)
        } else {
          await dispatch('fetchMaintenanceFiles', payload.id)
        }
      })
      .catch(error => {
        commit(CONST.SET_MAINTENANCE_ERRORS, error)
      })
  },
  uploadFile({ commit }, payload) {
    commit(CONST.SET_MAINTENANCE_ERRORS)
    if (!payload.id) return
    return this._vm.$axios
      .post(`/tasks/${payload.id}/files`, payload.body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async ({ data }) => {
        const { success, error, result } = data
        if (success) {
          commit(CONST.SET_MAINTENANCE_FILES, result[0]?.task_files)
        } else {
          commit(CONST.SET_MAINTENANCE_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_MAINTENANCE_ERRORS, error)
      })
  },
  resetMaintenanceTasks({ commit }) {
    commit(CONST.RESET_MAINTENANCE_TASKS)
  },
  async createTask({ commit }, payload) {
    try {
      const response = await this._vm.$axios.post('/tasks', payload)
      if (!response.data.success) {
        commit(CONST.SET_MAINTENANCE_ERRORS, response.data.error)
      }
      return response.data.success
    } catch (e) {
      commit(CONST.SET_MAINTENANCE_ERRORS, e)
    }
  },
  async addHistory({ commit, dispatch }, payload) {
    try {
      const response = await this._vm.$axios.post(
        `/tasks/${payload.task_id}/history`,
        payload
      )
      if (!response.data.success) {
        commit(CONST.SET_MAINTENANCE_ERRORS, response.data.error)
      } else {
        await dispatch('fetchTasks', { collection: 'current' })
        return response.data.success
      }
    } catch (e) {
      commit(CONST.SET_MAINTENANCE_ERRORS, e)
    }
  },
  async updateTask({ commit }, payload) {
    try {
      const response = await this._vm.$axios.put(
        `/tasks/${payload.task_id}`,
        payload
      )
      if (!response.data.success) {
        commit(CONST.SET_MAINTENANCE_ERRORS, response.data.error)
      }
      return response.data.success
    } catch (e) {
      commit(CONST.SET_MAINTENANCE_ERRORS, e)
    }
  },
  async fetchTask({ commit }, payload) {
    try {
      const api = `/tasks/${payload}`
      const {
        data: { success, error, result, meta },
      } = await this._vm.$axios.get(api)
      if (!success) {
        commit(CONST.SET_MAINTENANCE_ERRORS, error)
      }
      if (meta) {
        commit('assets/SET_ASSETS_EXIST', meta.assets_exist, { root: true })
        commit(CONST.SET_MAINTENANCE_META, meta)
      }
      commit(CONST.SET_MAINTENANCE_TASK, result)
      return success
    } catch (e) {
      commit(CONST.SET_MAINTENANCE_ERRORS, e)
    }
  },
  async fetchTasks({ commit }, payload) {
    try {
      const fetchTaskUrl = `/tasks?collection=${
        payload.collection
      }&page_size=20&page=${payload.page || 1}`
      const api = payload.asset_id
        ? `${fetchTaskUrl}&asset_id=${payload.asset_id}`
        : fetchTaskUrl
      const {
        data: { success, error, result, meta },
      } = await this._vm.$axios.get(api)
      if (!success) {
        commit(CONST.SET_MAINTENANCE_ERRORS, error)
      }
      if (meta) {
        commit('assets/SET_ASSETS_EXIST', meta.assets_exist, { root: true })
        commit(CONST.SET_MAINTENANCE_META, meta)
      }
      commit(CONST.SET_MAINTENANCE_TASKS, {
        result,
        loadMoreClicked: payload?.loadMoreClicked,
      })
      return success
    } catch (e) {
      commit(CONST.SET_MAINTENANCE_ERRORS, e)
    }
  },
  async fetchHistory({ commit }, payload) {
    try {
      const response = await this._vm.$axios.get(`/tasks/${payload}/history`)
      if (!response.data.success) {
        commit(CONST.SET_MAINTENANCE_ERRORS, response.data.error)
      }
      commit(CONST.SET_TASK_HISTORY, response.data.result)
    } catch (e) {
      commit(CONST.SET_MAINTENANCE_ERRORS, e)
    }
  },
  async deleteMaintenanceTaskById({ commit }, payload) {
    commit(CONST.SET_MAINTENANCE_ERRORS)
    if (!payload.taskId) {
      commit(
        CONST.SET_MAINTENANCE_ERRORS,
        'Maintenance Task id is required field'
      )
      return
    }
    let { success, error } = await MaintenanceService.deleteMaintenanceTaskById(
      payload
    )
    if (!success || error) {
      commit(CONST.SET_MAINTENANCE_ERRORS, error)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
