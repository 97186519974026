import Vue from 'vue'

const tryGet = value => {
  try {
    const r = value()
    if (typeof r !== 'undefined') {
      if (r === null) {
        return ''
      }
      return r
    }

    return ''
  } catch (e) {
    return ''
  }
}
Vue.prototype.$tryGet = tryGet
export default tryGet
