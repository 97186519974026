<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Inner -->
    <div class="sidenav-inner">
      <!-- Logo -->
      <div
        style="
          width: 100%;
          margin-top: 0px;
          background-color: white;
          padding-top: 0px;
        "
      >
        <img
          src="/img/logo.png"
          width="60px"
          height="60px"
          style="float: left; margin-left: 6px; margin-right: 4px"
          alt="logo"
        />
        <h4 style="margin-top: 18px">Asset Badger</h4>
      </div>
      <!-- / Logo -->
      <!-- <div class="sidenav-divider mb-1"></div>
      <sidenav-router-link
        icon="ion ion-ios-contact"
        to="/dashboard"
        :exact="true"
        >Dashboard</sidenav-router-link
      > -->
      <!-- <sidenav-router-link
        icon="ion d-block ion-md-map"
        :to="{ name: 'map2' }"
        :exact="true"
        >Map</sidenav-router-link
      > -->
      <sidenav-router-link
        icon="ion d-block ion-md-folder"
        :to="{ name: 'assets' }"
        :exact="true"
        >Assets</sidenav-router-link
      >
      <sidenav-router-link
        v-if="isAdminOrAssetManager"
        icon="ion d-block ion-ios-document"
        :to="{ name: 'assetTypes' }"
        :exact="true"
        >Asset Types</sidenav-router-link
      >
      <sidenav-router-link
        icon="ion d-block ion-md-hammer"
        :to="{ name: 'workOrders' }"
        :exact="true"
        >Work Orders</sidenav-router-link
      >
      <sidenav-router-link
        icon="ion d-block ion-ios-sync"
        :to="{ name: 'maintenance' }"
        :exact="true"
        >Maintenance</sidenav-router-link
      >
      <sidenav-router-link
        v-if="isAdminOrUserManager"
        icon="ion d-block ion-md-body"
        :to="{ name: 'settings' }"
        :exact="true"
        >Users</sidenav-router-link
      >
      <sidenav-router-link
        v-if="isAdminOrWorkManager"
        icon="ion d-block ion-md-list"
        :to="{ name: 'lists' }"
        :exact="true"
        >E.L.M.</sidenav-router-link
      >
      <!-- <sidenav-router-link
        icon="ion d-block ion-md-document"
        :to="{ name: 'reports' }"
        :exact="true"
        >Data Export</sidenav-router-link
      > -->
      <sidenav-router-link
        v-if="isAdminOrWorkManager"
        icon="ion d-block ion-md-settings"
        :to="{ name: 'configuration' }"
        :exact="true"
        >Settings</sidenav-router-link
      >
      <div class="sidenav-divider mb-1"></div>
      <sidenav-router-link
        icon="ion d-block ion-ios-person"
        :to="{ name: 'logout' }"
        :exact="true"
        >Sign Out</sidenav-router-link
      >
    </div>
  </sidenav>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
} from '@/vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical',
    },
  },
  computed: {
    ...mapGetters('auth', [
      'isAdminOrUserManager',
      'isAdminOrWorkManager',
      'isAdminOrAssetManager',
    ]),
    curClasses() {
      let bg = this.layoutSidenavBg

      if (
        this.orientation === 'horizontal' &&
        (bg.indexOf(' sidenav-dark') !== -1 ||
          bg.indexOf(' sidenav-light') !== -1)
      ) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0')
      )
    },
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
      )
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    },
  },
}
</script>
