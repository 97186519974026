import * as CONST from '../mutations/Assets'
import { getPath } from '@/helper'
const getDefaultState = () => ({
  assets: [],
  assetTypes: [],
  currentFolder: [],
  errors: [],
  selectedAssetType: {},
  selectedAsset: {},
  meta: {},
  foldersMeta: {},
  folderDetails: '',
  folders: [],
  assetsExist: false,
  assetFiles: [],
})

const state = getDefaultState()
const getters = {
  getAssets: state => state.assets,
  getAssetFiles: state => state.assetFiles,
  haveAssets: state => state.assetsExist || state.assets.length > 0,
  getFolders: state => state.folders || [],
  getAssetsMeta: state => state.meta,
  getAssetTypes: state => state.assetTypes,
  getSelectedAsset: state => state.selectedAsset,
  getSelectedAssetType: state => state.selectedAssetType,
  getCurrentFolderId: (state, getters) =>
    getters.getCurrentFolder ? getters.getCurrentFolder.id : null,
  getCurrentFolderDetails: state => state.folderDetails,
  assetsHaveErrors: state => state.errors && state.errors.length > 0,
  getWorkSummary: state => state.workSummary,
  getAssetsPath: state => {
    return getPath(state.meta)
  },
  getCurrentFolder: state => {
    const { folder_path_array } = state.meta
    if (!folder_path_array || !folder_path_array.length) return null
    return folder_path_array[folder_path_array.length - 1]
  },
  getFoldersPath: state => {
    return {
      breadcrumbs: getPath(state.foldersMeta),
      parentFolder: state.foldersMeta.folder_path,
    }
  },
  getParentAssetName: state => {
    return state.meta.parent_asset_name
  },
}
const mutations = {
  [CONST.RESET_ASSETS_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
  [CONST.SET_ASSETS]: (state, assets) => {
    state.assets = assets
  },
  [CONST.SET_ASSETS_EXIST]: (state, assetsExist) => {
    state.assetsExist = assetsExist
  },
  [CONST.SET_ASSET_TYPES]: (state, assetTypes) => {
    state.assetTypes = assetTypes
  },
  [CONST.SET_CURRENT_FOLDER]: (state, currentFolder) => {
    state.currentFolder = currentFolder
  },
  [CONST.SET_ASSET_ERRORS]: (state, errors = []) => {
    state.errors = errors
  },
  [CONST.SET_SELECTED_ASSET_TYPE]: (state, selectedAssetType) => {
    state.selectedAssetType = selectedAssetType
  },
  [CONST.SET_SELECTED_ASSET]: (state, selectedAsset) => {
    state.selectedAsset = selectedAsset
  },
  [CONST.SET_ASSET_META]: (state, assetMeta) => {
    state.meta = assetMeta
  },
  [CONST.SET_MORE_ASSET]: (state, assets) => {
    state.assets = [...state.assets, ...assets]
  },
  [CONST.SET_FOLDER_DETAILS]: (state, folderDetails) => {
    state.folderDetails = folderDetails
  },
  [CONST.SET_FOLDERS]: (state, folders) => {
    state.folders = folders
  },
  [CONST.SET_FOLDERS_META]: (state, folderMeta) => {
    state.foldersMeta = folderMeta
  },
  [CONST.SET_ASSET_FILES]: (state, files) => {
    state.assetFiles = files
  },
  [CONST.SET_ASSET_WORK_SUMMARY]: (state, summary) => {
    state.workSummary = summary
  },
}
const actions = {
  fetchAssetFiles({ commit }, id = '') {
    commit(CONST.SET_ASSET_ERRORS)
    if (!id) return
    return this._vm.$axios
      .get(`/assets/${id}/files`)
      .then(({ data }) => {
        const { success, error, result } = data
        if (success) {
          commit(CONST.SET_ASSET_FILES, result)
        } else {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  uploadAssetFiles({ commit }, payload) {
    commit(CONST.SET_ASSET_ERRORS)
    if (!payload.id) return
    return this._vm.$axios
      .post(`/assets/${payload.id}/files`, payload.body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async ({ data }) => {
        const { success, error, result } = data
        if (success) {
          commit(CONST.SET_ASSET_FILES, result[0]?.asset_files)
        } else {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  deleteAssetFileById({ commit, dispatch }, payload) {
    commit(CONST.SET_ASSET_ERRORS)
    if (!payload.id || !payload.fileId) {
      commit(CONST.SET_ASSET_ERRORS, 'Asset id and File id are required fields')
      return
    }
    return this._vm.$axios
      .delete(`/assets/${payload.id}/files/${payload.fileId}`)
      .then(async ({ data }) => {
        const { success, error } = data
        if (!success) {
          commit(CONST.SET_ASSET_ERRORS, error)
        } else {
          dispatch('fetchAssetFiles', payload.id)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  fetchAssets(
    { commit },
    { parentId = null, folderId = null, page = 1, search = '' }
  ) {
    commit(CONST.SET_ASSET_ERRORS)
    let params = `?page=${page}&search=${search}`
    if (folderId) {
      params = params + `&folder_id=${folderId}`
    }
    if (parentId) {
      params = params + `&parent_id=${parentId}`
    }
    return this._vm.$axios
      .get(`/assets${params}`)
      .then(response => {
        const { result, meta, success, error } = response.data
        if (success) {
          commit(CONST.SET_ASSET_META, meta)
          if (page > 1) {
            commit(CONST.SET_MORE_ASSET, result)
          } else {
            commit(CONST.SET_ASSETS, result)
          }
        } else {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  fetchAssetById({ dispatch, commit }, assetId = '') {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .get(`/assets/${assetId}`)
      .then(({ data }) => {
        const { result, meta, success, error } = data
        if (success) {
          dispatch('setSelectedAsset', result[0] || [])
          commit(CONST.SET_ASSET_META, meta)
        } else {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  fetchAssetByNum({ commit }, assetNum = '') {
    commit(CONST.SET_ASSET_ERRORS)
    if (!assetNum) {
      throw new Error('No Asset Number Provided')
    }
    return this._vm.$axios
      .get(`/assets/num/${assetNum}?include_work=1`)
      .then(({ data }) => {
        const { success, result } = data
        if (success) {
          return result && result[0]
        } else {
          throw new Error("Asset with provided number doesn't exist", assetNum)
        }
      })
      .catch(error => {
        throw new Error(error)
      })
  },
  updateAsset({ commit }, payload) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .put(`/assets/${payload.assetId}`, payload.body)
      .then(({ data }) => {
        const { success, error } = data

        if (!success && error) {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  updateAssetCustomFields({ commit }, payload) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .put(`/assets/${payload.assetId}/custom_fields`, payload)
      .then(({ data }) => {
        const { success, error } = data

        if (!success && error) {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  addAsset({ commit }, payload) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .post('/assets', payload)
      .then(({ data }) => {
        const { success, error, result = [] } = data

        if (!success && error) {
          commit(CONST.SET_ASSET_ERRORS, error)
        } else if (success && result.length) {
          return Promise.resolve(result[0])
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  addFolder({ commit }, payload) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .post('/folders', payload)
      .then(({ response }) => {
        const { success, error } = response.data

        if (!success && error) {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  addAssetType({ commit }, payload) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .post('/asset_types', payload)
      .then(({ response }) => {
        const { success, error } = response.data

        if (!success && error) {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  updateAssetType({ commit }, payload) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .put(`/asset_types/${payload.assetTypeId}`, payload.body)
      .then(({ response }) => {
        const { success, error } = response.data

        if (!success && error) {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  deleteAssetType({ commit }, assetTypeId) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .delete(`/asset_types/${assetTypeId}`)
      .then(({ response }) => {
        const { success, error } = response.data

        if (!success && error) {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  deleteAsset({ commit }, assetId) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .delete(`/assets/${assetId}`)
      .then(({ response }) => {
        const { success, error } = response.data

        if (!success && error) {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  fetchAssetTypes({ commit }) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .get(`/asset_types`)
      .then(response => {
        const { result, success, error } = response.data

        if (success) {
          commit(CONST.SET_ASSET_TYPES, result)
        } else {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  fetchAssetTypeById({ dispatch, commit }, assetTypeId = '') {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .get(`/asset_types/${assetTypeId}`)
      .then(({ data }) => {
        const { result, success, error } = data
        if (success) {
          dispatch('setSelectedAssetType', result[0] || [])
        } else {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  fetchFolderDetailsById({ commit }, folderId = '') {
    commit(CONST.SET_ASSET_ERRORS)
    if (!folderId) return
    return this._vm.$axios
      .get(`/folders/${folderId}`)
      .then(({ data }) => {
        const { result, success, error, meta } = data
        if (success) {
          commit(CONST.SET_FOLDER_DETAILS, { ...result[0], ...meta } || {})
        } else {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  fetchFolders({ commit }, parentFolderId = '') {
    commit(CONST.SET_ASSET_ERRORS)
    let apiPath = '/folders'
    if (parentFolderId) {
      apiPath += `?parent_id=${parentFolderId}`
    }
    return this._vm.$axios
      .get(apiPath)
      .then(({ data }) => {
        const { meta, result, success, error } = data
        if (success) {
          commit(CONST.SET_FOLDERS, result)
          commit(CONST.SET_FOLDERS_META, meta)
        } else {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  updateFolderDetails({ commit }, payload) {
    commit(CONST.SET_ASSET_ERRORS)
    if (!payload.folderId) return
    return this._vm.$axios
      .put(`/folders/${payload.folderId}`, payload.body)
      .then(({ data }) => {
        const { success, error } = data
        if (!success) {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  deleteFolderById({ commit }, folderId) {
    commit(CONST.SET_ASSET_ERRORS)
    if (!folderId) return
    return this._vm.$axios
      .delete(`/folders/${folderId}`)
      .then(({ data }) => {
        const { success, error } = data
        if (!success) {
          commit(CONST.SET_ASSET_ERRORS, error)
        } else {
          commit(CONST.SET_FOLDER_DETAILS, {})
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  fetchAssetWorkSummary({ commit }) {
    commit(CONST.SET_ASSET_ERRORS)
    return this._vm.$axios
      .get(`/assets/worksummary`)
      .then(response => {
        const { result, success, error } = response.data

        if (success) {
          commit(CONST.SET_ASSET_WORK_SUMMARY, result)
        } else {
          commit(CONST.SET_ASSET_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ASSET_ERRORS, error)
      })
  },
  async fetchAssetFeatureData(
    _,
    { layerUrl, token, uniqueFieldName, uniqueFieldValue }
  ) {
    const res = await this._vm.$axios.get(`${layerUrl}/query`, {
      params: {
        where: `${uniqueFieldName}=${uniqueFieldValue}`,
        outFields: '*',
        f: 'json',
        token: token,
      },
      transformRequest: (data, headers) => {
        delete headers['Authorization']
        return data
      },
    })
    return res
  },
  async fetchFeatureLayerInfo(_, { layerUrl, token }) {
    const res = await this._vm.$axios.get(`${layerUrl}`, {
      params: {
        f: 'json',
        token: token,
      },
      transformRequest: (data, headers) => {
        delete headers['Authorization']
        return data
      },
    })
    console.log(res)
    return res
  },
  setSelectedAsset({ commit }, asset = {}) {
    commit(CONST.SET_SELECTED_ASSET, asset)
  },
  setSelectedAssetType({ commit }, assetType = {}) {
    commit(CONST.SET_SELECTED_ASSET_TYPE, assetType)
  },
  setAssetErrors({ commit }, errors = []) {
    commit(CONST.SET_ASSET_ERRORS, errors)
  },
  resetMeta({ commit }) {
    commit(CONST.SET_ASSET_META, {})
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
