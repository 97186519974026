import * as CONST from '../mutations/Lists'
import ListService from '../../services/Lists'

const getDefaultState = () => ({
  errors: [],
  equipments: [],
  labors: [],
  materials: [],
  selectedItemData: null,
})

const state = getDefaultState()

const getters = {
  getEquipments: state => {
    return state.equipments
  },
  getLabors: state => {
    return state.labors
  },
  getMaterials: state => {
    return state.materials
  },
  getSelectedItemData: state => {
    return state.selectedItemData
  },
}

const mutations = {
  [CONST.RESET_LISTS_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
  [CONST.SET_LISTS_ERRORS]: (state, errors = []) => {
    state.errors = errors
  },
  [CONST.SET_LIST_ITEMS]: (state, { type, data }) => {
    state[type + 's'] = data
  },
  [CONST.SET_SELECTED_ITEM_DATA]: (state, data) => {
    state.selectedItemData = data
  },
}

const actions = {
  async fetchItemsByType({ commit }, type = '') {
    commit(CONST.SET_LISTS_ERRORS)

    if (!type) {
      throw new Error('Item Type is Required')
    }

    // const transformedTypeName =
    //   'get' + type.charAt(0).toUpperCase() + type.slice(1) + 's'

    // const data = getters[transformedTypeName]

    // if (data.length) {
    //   return data
    // }

    const response = await ListService.fetchItemsByType(type)

    if (response.type == 'success') {
      commit(CONST.SET_LIST_ITEMS, { type, data: response.data })
    }

    if (response.type == 'error') {
      commit(CONST.SET_LISTS_ERRORS, response.data)
    }
  },
  async fetchItemByID({ commit }, id) {
    // TODO : Reset Selected Item

    if (!id) {
      return
    }

    const response = await ListService.fetchItemByID(id)

    if (response.type == 'success') {
      commit(CONST.SET_SELECTED_ITEM_DATA, response.data)
    }

    if (response.type == 'error') {
      commit(CONST.SET_LISTS_ERRORS, response.data)
    }
  },
  async createItem({ commit }, data) {
    if (!data) {
      throw new Error('Item Data is Required')
    }

    const response = await ListService.createItem(data)

    if (response.type == 'error') {
      commit(CONST.SET_LISTS_ERRORS, response.data)
    }
  },
  async updateItem({ commit }, data) {
    if (!data) {
      throw new Error('Item Data is Required')
    }

    const response = await ListService.updateItem(data)

    if (response.type == 'error') {
      commit(CONST.SET_LISTS_ERRORS, response.data)
    }
  },
  async deleteItem({ commit }, id) {
    if (!id) {
      throw new Error('Item ID is Required')
    }

    const response = await ListService.deleteItem(id)

    if (response.type == 'error') {
      commit(CONST.SET_LISTS_ERRORS, response.data)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
