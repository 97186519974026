import Vue from 'vue'
import Vuex from 'vuex'
import assets from './modules/Assets'
import auth from './modules/Auth'
import users from './modules/Users.js'
import work from './modules/Work'
import maintenance from './modules/Maintenance'
import organization from './modules/organization'
import lists from './modules/Lists'
import dashboard from './modules/dashboard'
import Reports from './modules/Reports'
import Configuration from './modules/Configuration'
import WorkOrder from './modules/WorkOrder'
import TaskTypes from './modules/TaskTypes'
import Shared from './modules/Shared'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    assets,
    auth,
    users,
    work,
    maintenance,
    organization,
    lists,
    dashboard,
    Reports,
    Configuration,
    WorkOrder,
    TaskTypes,
    Shared,
  },
})

export default store
