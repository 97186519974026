import Vue from 'vue'
import axios from 'axios'
import { vm } from '../main'
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
})
instance.interceptors.request.use(request => {
  const token = localStorage.getItem('token')
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`
  }

  return request
})
const showNotification = message => {
  vm.$bvToast.toast(message, {
    title: 'Error!',
    variant: 'danger',
    solid: true,
    autoHideDelay: 3000,
  })
}
instance.interceptors.response.use(
  response => {
    if (
      localStorage.getItem('token') !== null &&
      response &&
      response.status === 401
    ) {
      localStorage.clear()
      window.location.reload()
    }
    return response
  },
  error => {
    if (
      vm.$tryGet(() => error.response.status) === 403 &&
      localStorage.getItem('token')
    ) {
      showNotification('Invalid credentials')

      setTimeout(() => {
        localStorage.clear()
        window.location.replace('/login')
      }, 3000)
    } else if (
      vm.$tryGet(() => error.response.status) === 401 &&
      localStorage.getItem('token')
    ) {
      localStorage.clear()
      window.location.reload()
    } else {
      if (
        vm.$tryGet(() => error.response.status) >= 500 ||
        vm.$tryGet(() => error.message) === 'Network Error'
      ) {
        console.error({ error })
        const message = 'An unknown error has occured'
        showNotification(message)
        return Promise.reject(message)
      }

      const errorArray = vm.$tryGet(() => error.response.data.error)
        ? error.response.data.error
        : ''
      if (errorArray && errorArray.length) {
        const errorMessages = errorArray.join('\n')
        errorArray.forEach(text => {
          showNotification(text)
        })
        return Promise.reject(errorMessages)
      }
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$axios = instance
export { instance }
