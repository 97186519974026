import * as CONST from '../mutations/Shared'

const getDefaultState = () => ({
  fileDetails: null,
  fileDetailsError: [],
})

const state = getDefaultState()

const getters = {
  getFileDetails: state => state.fileDetails,
  getFileDetailsError: state => state.fileDetailsError,
}

const mutations = {
  [CONST.SET_FILE_DETAILS]: (state, data) => {
    state.fileDetails = data
  },
  [CONST.SET_FILE_DETAILS_ERRORS]: (state, data) => {
    state.fileDetailsError = data
  },
}

const actions = {
  fetchFileDetails({ commit }, id) {
    if (!id) return

    return this._vm.$axios
      .get(`/files/${id}`)
      .then(({ data }) => {
        const { success, result, error } = data
        if (success) {
          commit(CONST.SET_FILE_DETAILS, result[0])
        } else {
          commit(CONST.SET_FILE_DETAILS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_FILE_DETAILS_ERRORS, error)
      })
  },
  updateFileDetails({ commit }, payload) {
    const { file_id } = payload

    return this._vm.$axios
      .put(`/files/${file_id}`, payload)
      .then(({ data }) => {
        const { success, result, error } = data
        if (success) {
          return {
            success: true,
            result,
          }
        } else {
          commit(CONST.SET_FILE_DETAILS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_FILE_DETAILS_ERRORS, error)
      })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
