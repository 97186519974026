export const RESET_ASSETS_STATE = 'RESET_ASSETS_STATE'
export const SET_ASSETS = 'SET_ASSETS'
export const SET_ASSET_TYPES = 'SET_ASSET_TYPES'
export const SET_SELECTED_ASSET_TYPE = 'SET_SELECTED_ASSET_TYPE'
export const SET_SELECTED_ASSET = 'SET_SELECTED_ASSET'
export const SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER'
export const SET_ASSET_ERRORS = 'SET_ASSET_ERRORS'
export const SET_ASSET_META = 'SET_ASSET_META'
export const SET_MORE_ASSET = 'SET_MORE_ASSET'
export const SET_FOLDER_DETAILS = 'SET_FOLDER_DETAILS'
export const SET_FOLDERS = 'SET_FOLDERS'
export const SET_FOLDERS_META = 'SET_FOLDERS_META'
export const SET_ASSETS_EXIST = 'SET_ASSETS_EXIST'
export const SET_ASSET_FILES = 'SET_ASSET_FILES'
export const SET_ASSET_WORK_SUMMARY = 'SET_ASSET_WORK_SUMMARY'
