import * as CONST from '../mutations/Dashboard'
// import { getPath } from '@/helper'
const getDefaultState = () => ({
  itemsList: [],
  errors: [],
})

const state = getDefaultState()
const getters = {
  getDashboardItemsList: state => state.itemsList,
}
const mutations = {
  [CONST.SET_ITEMS_LIST]: (state, itemsList) => {
    state.itemsList = itemsList
  },
  [CONST.SET_ITEMS_ERRORS]: (state, errors = []) => {
    state.errors = errors
  },
}
const actions = {
  fetchDashboardItemsList({ commit }) {
    return this._vm.$axios
      .get(`/dashboard`)
      .then(response => {
        const { result, success, error } = response.data
        if (success) {
          commit(CONST.SET_ITEMS_LIST, result)
        } else {
          commit(CONST.SET_ITEMS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_ITEMS_ERRORS, error)
      })
  },
  setItemsErrors({ commit }, errors = []) {
    commit(CONST.SET_ITEMS_ERRORS, errors)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
