import * as CONST from '../mutations/organization'
import tryGet from '../../plugins/TryGet'
const getDefaultState = () => ({
  errors: [],
  settings: [],
  featureLayers: [],
  selectedFeatureLayer: {},
  featureLayerErrors: [],
})
const state = getDefaultState()
const getters = {
  getOrgSettings: state => state.settings,
  getOrgErrors: state => state.errors,
  haveOrgErrors: state => !!tryGet(() => state.errors.length),
  getFeatureLayers: state => state.featureLayers,
  getSelectedFeatureLayer: state => state.selectedFeatureLayer,
  getFeatureLayerErrors: state => state.featureLayerErrors,
  haveFeatureLayerErrors: state =>
    !!tryGet(() => state.featureLayerErrors.length),
  isMappingTypeDefault: state =>
    tryGet(() => state.settings[0].mapping_type) === 'Default',
}
const mutations = {
  [CONST.SET_ORG_ERRORS]: (state, errors) => {
    state.errors = errors
  },
  [CONST.SET_ORG_SETTINGS]: (state, settings) => {
    state.settings = settings
  },
  [CONST.SET_FEATURE_LAYERS]: (state, featureLayers = []) => {
    state.featureLayers = featureLayers
  },
  [CONST.SET_SELECTED_FEATURE_LAYERS]: (state, selectedFeatureLayer = []) => {
    state.selectedFeatureLayer = selectedFeatureLayer
  },
  [CONST.SET_FEATURE_LAYER_ERRORS]: (state, featureLayerErrors = []) => {
    state.featureLayerErrors = featureLayerErrors
  },
}
const actions = {
  async joinOrganization({ commit }, payload) {
    commit(CONST.SET_ORG_ERRORS, [])
    try {
      const response = await this._vm.$axios.post('/orgs/join', payload)
      return response.data
    } catch (error) {
      commit(CONST.SET_ORG_ERRORS, error)
    }
  },

  async createOrganization({ commit }, payload) {
    commit(CONST.SET_ORG_ERRORS, [])
    try {
      const response = await this._vm.$axios.post('/orgs', payload)
      return response.data
    } catch (error) {
      commit(CONST.SET_ORG_ERRORS, error)
    }
  },

  async fetchOrganizationSettings({ commit }) {
    commit(CONST.SET_ORG_ERRORS, [])
    const {
      data: { result },
    } = await this._vm.$axios.get('/orgs/settings')
    commit(CONST.SET_ORG_SETTINGS, result)
  },
  async updateOrganizationSettings({ commit, dispatch }, payload) {
    commit(CONST.SET_ORG_ERRORS, [])
    try {
      const { data } = await this._vm.$axios.put('/orgs/settings', payload)
      await dispatch('fetchOrganizationSettings')
      return data
    } catch (error) {
      commit(CONST.SET_ORG_ERRORS, error)
    }
  },
  async fetchFeatureLayers({ commit }) {
    commit(CONST.SET_FEATURE_LAYER_ERRORS, [])
    return this._vm.$axios
      .get('/orgs/feature_layers')
      .then(({ data }) => {
        const { result, success, error } = data
        if (success) {
          commit(CONST.SET_FEATURE_LAYERS, result)
        } else {
          commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
      })
  },
  async fetchFeatureLayerById({ commit }, id) {
    if (!id) throw new Error('Please provide a valid feature id')
    commit(CONST.SET_FEATURE_LAYER_ERRORS, [])
    return this._vm.$axios
      .get(`/orgs/feature_layers/${id}`)
      .then(({ data }) => {
        const { result, success, error } = data
        if (success) {
          commit(CONST.SET_SELECTED_FEATURE_LAYERS, result)
        } else {
          commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
      })
  },
  async createFeatureLayer({ commit, dispatch }, payload) {
    commit(CONST.SET_FEATURE_LAYER_ERRORS, [])
    return this._vm.$axios
      .post('/orgs/feature_layers', payload)
      .then(({ data }) => {
        const { success, error } = data
        if (!success && error) {
          commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
        } else {
          dispatch('fetchFeatureLayers')
        }
      })
      .catch(error => {
        commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
      })
  },
  async updateFeatureLayer({ commit, dispatch }, { id, body }) {
    if (!id) throw new Error('Please provide a valid feature id')
    commit(CONST.SET_FEATURE_LAYER_ERRORS, [])
    return this._vm.$axios
      .put(`/orgs/feature_layers/${id}`, body)
      .then(({ data }) => {
        const { success, error } = data

        if (!success && error) {
          commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
        } else {
          dispatch('fetchFeatureLayers')
        }
      })
      .catch(error => {
        commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
      })
  },
  async deleteFeatureLayer({ commit, dispatch }, id) {
    commit(CONST.SET_FEATURE_LAYER_ERRORS, [])
    return this._vm.$axios
      .delete(`/orgs/feature_layers/${id}`)
      .then(({ data }) => {
        const { success, error } = data

        if (!success && error) {
          commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
        } else {
          dispatch('fetchFeatureLayers')
        }
      })
      .catch(error => {
        commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
      })
  },

  async registerEsri({ commit }, payload) {
    return this._vm.$axios
      .post('/orgs/esri_registration', payload)
      .then(({ data }) => {
        const { success, error } = data
        if (!success && error) {
          commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
        } else {
          return success
        }
      })
      .catch(error => {
        commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
      })
  },
  moveFeatureLayers({ dispatch, commit }, payload) {
    const { id, direction } = payload
    return this._vm.$axios
      .post(`/orgs/feature_layers/${id}/${direction}`, {})
      .then(({ data }) => {
        const { success, error } = data

        if (!success && error) {
          commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
        } else {
          dispatch('fetchFeatureLayers')
        }
      })
      .catch(error => {
        commit(CONST.SET_FEATURE_LAYER_ERRORS, error)
      })
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
