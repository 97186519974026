import WorkOrderLinkedItemService from '../../services/WorkOrderLinkedItemService'
import * as CONST from '../mutations/Work'
const getDefaultState = () => ({
  workOrders: [],
  errors: [],
  meta: {},
  files: [],
  workOrderById: null,
  workSelectedAsset: null,
  isMineWorkOrders: false,
  workOrderNotes: [],
  assetWorkOrders: [],
  selectedWorkOrderUsageItemData: null,
  equipments: [],
  labors: [],
  materials: [],
})
const state = getDefaultState()
const getters = {
  getWorkOrders: state => state.workOrders,
  getFiles: state => state.files || [],
  getWorkOrdersMeta: state => state.meta,
  getWorkOrderNotes: state => {
    if (!state.workOrderNotes || !state.workOrderNotes.length) return []
    return state.workOrderNotes.map(workOrderNote => ({
      note: workOrderNote.note,
      date: new Date(workOrderNote.created_on).toLocaleDateString(),
      name: `${workOrderNote.created_by.first_name} ${workOrderNote.created_by.last_name}`,
    }))
  },
  getWorkOrdersErrors: state => state.errors,
  workOrdersHaveErrors: state => state.errors && state.errors.length > 0,
  getWorkOrderById: state =>
    (state.workOrderById && state.workOrderById[0]) || {},
  getWorkSelectedAsset: state => state.workSelectedAsset,
  getWorkSelectedAssetId: (state, getters) => {
    if (!getters.getWorkSelectedAsset) return null
    return getters.getWorkSelectedAsset.asset_id
  },
  getWorkOrderBasicInfo: state =>
    !state.workOrders.length
      ? []
      : state.workOrders.map(
          ({
            title,
            workorder_id,
            workorder_num,
            assigned_to,
            due_on_text,
            priority,
            is_overdue,
            asset,
          }) => {
            const { user_id, first_name, last_name } = assigned_to
            return {
              title,
              workorder_id,
              workorder_num,
              priority,
              isOverdue: is_overdue,
              assigned: {
                user_id,
                name: !user_id ? '(Unassigned)' : `${first_name} ${last_name}`,
                due: due_on_text,
              },
              assetName: asset.name,
            }
          }
        ),
  getIsMineWorkOrders: state => state.isMineWorkOrders,
  assetWorkOrders: state => state.assetWorkOrders || [],
  getSelectedWorkOrderUsageItemData: state => {
    return state.selectedWorkOrderUsageItemData
  },
  getWorkOrderEquipments: state => {
    return state.equipments
  },
  getWorkOrderLabors: state => {
    return state.labors
  },
  getWorkOrderMaterials: state => {
    return state.materials
  },
}
const mutations = {
  [CONST.SET_WORK_ORDERS_META]: (state, meta) => {
    state.meta = meta
  },
  [CONST.SET_WORK_SELECTED_ASSET]: (state, item) => {
    state.workSelectedAsset = item
  },
  [CONST.SET_WORK_ORDERS]: (state, workOrders) => {
    state.workOrders = [...state.workOrders, ...workOrders]
  },
  [CONST.RESET_WORK_ORDERS]: state => {
    state.workOrders = []
  },
  [CONST.SET_WORK_ORDERS_BY_ID]: (state, workOrderById) => {
    state.workOrderById = workOrderById
  },
  [CONST.RESET_WORK_ORDERS_BY_ID]: state => {
    state.workSelectedAsset = null
    localStorage.setItem('assetId', '')
  },
  [CONST.SET_WORK_ORDERS_ERRORS]: (state, errors = []) => {
    state.errors = errors
  },
  [CONST.SET_IS_MINE_WORKORDERS]: (state, value) => {
    state.isMineWorkOrders = value
  },
  [CONST.SET_WORK_ORDER_NOTES]: (state, workOrderNotes) => {
    state.workOrderNotes = workOrderNotes
  },
  [CONST.SET_ASSET_WORK_ORDERS]: (state, workorders) => {
    state.assetWorkOrders = workorders
  },
  [CONST.SET_WORKORDER_FILES]: (state, files) => {
    state.files = files
  },
  [CONST.SET_WORK_ORDERS_USAGE_ITEM_BY_TYPE]: (state, { type, data }) => {
    state[type + 's'] = data
  },
  [CONST.SET_SELECTED_WORK_ORDER_USAGE_ITEM_DATA]: (state, data) => {
    state.selectedWorkOrderUsageItemData = data
  },
}
const actions = {
  fetchWorkorderFiles({ commit }, id = '') {
    commit(CONST.SET_WORK_ORDERS_ERRORS)
    if (!id) return
    return this._vm.$axios
      .get(`/workorders/${id}/files`)
      .then(({ data }) => {
        const { success, error, result } = data
        if (success) {
          commit(CONST.SET_WORKORDER_FILES, result)
        } else {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  deleteFileById({ commit, dispatch }, payload) {
    commit(CONST.SET_WORK_ORDERS_ERRORS)
    if (!payload.id || !payload.fileId) {
      commit(
        CONST.SET_WORK_ORDERS_ERRORS,
        'Workorder id and File id are required fields'
      )
      return
    }
    return this._vm.$axios
      .delete(`/workorders/${payload.id}/files/${payload.fileId}`)
      .then(async ({ data }) => {
        const { success, error } = data
        if (!success) {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        } else {
          await dispatch('fetchWorkorderFiles', payload.id)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  uploadFile({ commit }, payload) {
    commit(CONST.SET_WORK_ORDERS_ERRORS)
    if (!payload.id) return
    return this._vm.$axios
      .post(`/workorders/${payload.id}/files`, payload.body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async ({ data }) => {
        const { success, error, result } = data
        if (success) {
          commit(CONST.SET_WORKORDER_FILES, result[0]?.workorder_files)
        } else {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  setSelectedAsset({ commit }, item) {
    commit(CONST.SET_WORK_SELECTED_ASSET, item)
  },
  setIsMineWorkOrders({ commit }, value) {
    commit(CONST.SET_IS_MINE_WORKORDERS, value)
  },
  createWorkOrderNote({ commit }, payload) {
    commit(CONST.SET_WORK_ORDERS_ERRORS, [])
    return this._vm.$axios
      .post(`/workorders/${payload.workOrderId}/notes`, payload.body)
      .then(({ response }) => {
        const { success, error } = response.data
        if (!success && error) {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  fetchWorkOrderNotes({ commit }, { id = '', reset = true }) {
    if (reset) {
      commit(CONST.SET_WORK_ORDERS_ERRORS, [])
    }
    return this._vm.$axios
      .get(`/workorders/${id}/notes`)
      .then(response => {
        if (!response) {
          return Promise.reject(
            'Something went wrong while fetching order notes'
          )
        }
        const { result, success, error } = response.data
        if (success) {
          commit(CONST.SET_WORK_ORDER_NOTES, result)
        } else {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  fetchAssetWorkOrders({ commit }, id) {
    commit(CONST.SET_ASSET_WORK_ORDERS, [])
    return this._vm.$axios
      .get(`/workorders?asset_id=${id}&include_completed=${true}`)
      .then(({ data }) => {
        const { success, result } = data
        if (success) {
          commit(CONST.SET_ASSET_WORK_ORDERS, result)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  fetchWorkOrders({ commit }, queryParam) {
    commit(CONST.SET_WORK_ORDERS_ERRORS, [])
    const path = !queryParam ? '/workorders' : `/workorders?${queryParam}`
    return this._vm.$axios
      .get(path)
      .then(response => {
        const { result, success, error, meta } = response.data
        if (meta) {
          commit(CONST.SET_WORK_ORDERS_META, meta)
          commit('assets/SET_ASSETS_EXIST', meta.assets_exist, { root: true })
        }
        if (success) {
          commit(CONST.SET_WORK_ORDERS, result)
        } else {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  resetWorkOrders({ commit }) {
    commit(CONST.RESET_WORK_ORDERS)
  },
  fetchWorkOrdersById({ commit }, id) {
    commit(CONST.SET_WORK_ORDERS_ERRORS, [])
    commit(CONST.SET_WORK_ORDERS_BY_ID, null)
    return this._vm.$axios
      .get(`/workorders/${id}`)
      .then(response => {
        const { result, success, error } = response.data
        if (success) {
          commit(CONST.SET_WORK_ORDERS_BY_ID, result)
        } else {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  createWorkOrders({ commit }, payload) {
    commit(CONST.SET_WORK_ORDERS_ERRORS, [])
    return this._vm.$axios
      .post('/workorders', payload)
      .then(({ data }) => {
        const { success, error, result = [] } = data
        if (!success && error) {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        } else if (success && result.length) {
          return Promise.resolve(result[0])
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  updateWorkOrders({ commit }, payload) {
    commit(CONST.SET_WORK_ORDERS_ERRORS, [])
    return this._vm.$axios
      .put(`/workorders/${payload.workorder_id}`, payload)
      .then(({ data }) => {
        const { success, error } = data
        if (!success && error) {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  updateWorkOrdersCustomFields({ commit }, payload) {
    commit(CONST.SET_WORK_ORDERS_ERRORS, [])
    return this._vm.$axios
      .put(`/workorders/${payload.workorder_id}/custom_fields`, payload)
      .then(({ data }) => {
        const { success, error } = data
        if (!success && error) {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  resetWorkOrdersById({ commit }) {
    commit(CONST.RESET_WORK_ORDERS_BY_ID)
  },
  completeWorkOrders({ commit }, payload) {
    commit(CONST.SET_WORK_ORDERS_ERRORS, [])
    return this._vm.$axios
      .post(`/workorders/${payload.id}/complete`, payload.note)
      .then(({ response }) => {
        const { success, error } = response.data
        if (!success && error) {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  deleteWorkOrderById({ commit }, id) {
    commit(CONST.SET_WORK_ORDERS_ERRORS, [])
    return this._vm.$axios
      .delete(`/workorders/${id}`)
      .then(({ response }) => {
        const { success, error } = response.data
        if (!success && error) {
          commit(CONST.SET_WORK_ORDERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_WORK_ORDERS_ERRORS, error)
      })
  },
  async fetchWorkOderUsageItemByByItemType({ commit }, payload) {
    const { itemType, workOrderID } = payload

    if (!itemType) {
      throw new Error('Item Type is Required')
    }

    if (!workOrderID) {
      throw new Error('Work Order ID is Required')
    }

    const response = await WorkOrderLinkedItemService.fetchWorkOderUsageItemByByItemType(
      itemType,
      workOrderID
    )

    if (response.type == 'success') {
      commit(CONST.SET_WORK_ORDERS_USAGE_ITEM_BY_TYPE, {
        type: itemType,
        data: response.data,
      })
    }

    if (response.type == 'error') {
      commit(CONST.SET_WORK_ORDERS_ERRORS, response.data)
    }
  },
  async fetchItemByID({ commit }, id) {
    if (!id) {
      throw new Error('Work Order Usage Item ID is Required')
    }

    const response = await WorkOrderLinkedItemService.fetchItemByID(id)

    if (response.type == 'success') {
      commit(CONST.SET_SELECTED_WORK_ORDER_USAGE_ITEM_DATA, response.data)
    }

    if (response.type == 'error') {
      commit(CONST.SET_WORK_ORDERS_ERRORS, response.data)
    }
  },
  async createItem({ commit }, data) {
    if (!data) {
      throw new Error('Item Data is Required')
    }

    const response = await WorkOrderLinkedItemService.createItem(data)

    if (response.type == 'error') {
      commit(CONST.SET_WORK_ORDERS_ERRORS, response.data)
    }
  },
  async updateItem({ commit }, data) {
    if (!data) {
      throw new Error('Item Data is Required')
    }

    const response = await WorkOrderLinkedItemService.updateItem(data)

    if (response.type == 'error') {
      commit(CONST.SET_WORK_ORDERS_ERRORS, response.data)
    }
  },
  async deleteItem({ commit }, id) {
    if (!id) {
      throw new Error('Work Order Usage Item ID is Required')
    }

    const response = await WorkOrderLinkedItemService.deleteItem(id)

    if (response.type == 'error') {
      commit(CONST.SET_WORK_ORDERS_ERRORS, response.data)
    }
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
