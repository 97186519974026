// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/index'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import globals from './globals'

import './plugins/axios'
import './plugins/TryGet'
import './plugins/vueLeaflet'

import Popper from 'popper.js'
import BaseButton from '@/components/Buttons/BaseButton.vue'
Vue.component('BaseButton', BaseButton)
// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)

// Global RTL flag
Vue.mixin({
  data: globals,
})

const vm = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export { vm }
