import * as CONST from '../mutations/TaskTypes'
import TaskTypesService from '../../services/TaskTypes'

const state = {
  taskTypes: [],
  taskTypesById: {},
  errors: [],
}

const getters = {
  getTaskTypes: state => state.taskTypes,
  getTaskTypesById: state => state.taskTypesById,
  getErrors: state => state.errors,
}

const mutations = {
  [CONST.SET_ALL_TASK_TYPES]: (state, taskTypes) => {
    state.taskTypes = taskTypes
  },
  [CONST.SET_TASK_TYPE_BY_ID]: (state, taskType) => {
    state.taskTypesById = taskType
  },
  [CONST.SET_TASK_TYPE_ERRORS]: (state, errors = []) => {
    state.errors = errors
  },
}

const actions = {
  async fetchAllTaskTypes({ commit }) {
    const { type, data } = await TaskTypesService.getAllTaskTypes()
    if (type === 'success') {
      commit(CONST.SET_ALL_TASK_TYPES, data)
    } else {
      commit(CONST.SET_TASK_TYPE_ERRORS, data)
    }
  },
  async fetchTaskTypeByID({ commit }, id) {
    if (!id) {
      return
    }
    const response = await TaskTypesService.getTaskTypeById(id)

    if (response.type === 'success') {
      commit(CONST.SET_TASK_TYPE_BY_ID, response.data)
    }

    if (response.type === 'error') {
      commit(CONST.SET_TASK_TYPE_ERRORS, response.data)
    }
  },
  async createTaskType({ commit }, data) {
    if (!data) {
      throw new Error('Task Type Data is Required')
    }

    const response = await TaskTypesService.createTaskType(data)

    if (response.type === 'error') {
      commit(CONST.SET_TASK_TYPE_ERRORS, response.data)
    }
  },
  async updateTaskType({ commit }, data) {
    if (!data) {
      throw new Error('Task Type Data is Required')
    }

    const response = await TaskTypesService.updateTaskType(data)

    if (response.type == 'error') {
      commit(CONST.SET_TASK_TYPE_ERRORS, response.data)
    }
  },
  async deleteTaskType({ commit }, id) {
    if (!id) {
      throw new Error('Task Type ID is Required')
    }

    const response = await TaskTypesService.deleteTaskType(id)

    if (response.type === 'error') {
      commit(CONST.SET_TASK_TYPE_ERRORS, response.data)
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
