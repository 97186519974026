import { instance as axios } from '../plugins/axios'

/**
 *
 * @param {*} id
 * @returns
 */
async function deleteMaintenanceTaskById(payload) {
  return await axios.delete(`/tasks/${payload.taskId}`)
}

export default {
  deleteMaintenanceTaskById,
}
