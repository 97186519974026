import { instance as axios } from '../plugins/axios'

async function getAllCustomFields() {
  return await axios
    .get(`/custom_fields`)
    .then(({ data }) => {
      const { success, error, result } = data
      if (success) {
        return prepareResponse('success', result)
      }

      if (error) {
        return prepareResponse('error', error)
      }
    })
    .catch(error => {
      return prepareResponse('error', error)
    })
}
async function getCustomFieldById(id) {
  return await axios
    .get(`/custom_fields/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data
      if (success) {
        return prepareResponse('success', result)
      }

      if (error) {
        return prepareResponse('error', error)
      }
    })
    .catch(error => {
      return prepareResponse('error', error)
    })
}
async function updateCustomField(data) {
  return axios
    .put(`/custom_fields/${data.pop()}`, data.pop())
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return prepareResponse('success', result)
      }

      if (error) {
        return prepareResponse('error', error)
      }
    })
    .catch(error => {
      return prepareResponse('error', error)
    })
}
async function deleteCustomField(id) {
  return axios
    .delete(`/custom_fields/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return prepareResponse('success', result.success)
      }

      if (error) {
        return prepareResponse('error', error)
      }
    })
    .catch(error => {
      return prepareResponse('error', error)
    })
}
async function createCustomField(data) {
  return axios
    .post(`/custom_fields`, data)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return prepareResponse('success', result.custom_field_id)
      }

      if (error) {
        return prepareResponse('error', error)
      }
    })
    .catch(error => {
      return prepareResponse('error', error)
    })
}

function prepareResponse(messageType, data) {
  return {
    type: messageType,
    data: data,
  }
}

export default {
  getAllCustomFields,
  getCustomFieldById,
  updateCustomField,
  deleteCustomField,
  createCustomField,
}
