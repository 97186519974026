import * as CONST from '../mutations/Users'
const getDefaultState = () => ({
  usersOfOrganizations: [],
  invitations: [],
  errors: [],
  users: [],
  currentUser: [],
})

const state = getDefaultState()
const getters = {
  getUsersOfOrganization: state => state.usersOfOrganizations,
  getUsers: state => state.users,
  getInvitations: state => {
    const { invitations } = state
    if (invitations.length) {
      return invitations.map(
        ({
          user_group_id,
          user_group_name,
          invited_by,
          invitation_created_on,
        }) => ({
          value: user_group_id,
          text: user_group_name,
          invited_by,
          invitation_created_on,
        })
      )
    }
    return state.invitations
  },
  getUserErrors: state => state.errors,
  usersHaveErrors: state => state.errors && state.errors.length,
  getAssignedToUsers: state => {
    const defaultObject = [
      {
        value: null,
        text: 'Select User',
      },
    ]
    if (!state.users || !state.users.length) return defaultObject
    return [
      ...defaultObject,
      ...state.users.map(user => ({
        value: user.user_id,
        text: `${user.last_name}, ${user.first_name}`,
      })),
    ]
  },
}
const mutations = {
  [CONST.RESET_USERS_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
  [CONST.SET_USERS_ERRORS]: (state, errors) => {
    state.errors = errors
  },
  [CONST.SET_ORGANIZATION_USERS]: (state, usersOfOrganizations) => {
    state.usersOfOrganizations = usersOfOrganizations
  },
  [CONST.SET_INVITATIONS]: (state, invitations) => {
    state.invitations = invitations
  },
  [CONST.SET_USERS]: (state, users) => {
    state.users = users
  },
}
const actions = {
  fetchInvitations({ commit }) {
    return this._vm.$axios
      .get(`/orgs/invitations`)
      .then(response => {
        const { result, success, error } = response.data
        if (success) {
          commit(CONST.SET_INVITATIONS, result)
        } else {
          commit(CONST.SET_USERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_USERS_ERRORS, error)
      })
  },
  fetchOrganizationUsers({ commit }) {
    return this._vm.$axios
      .get(`/users`)
      .then(response => {
        const { result, success, error } = response.data
        if (success) {
          commit(CONST.SET_ORGANIZATION_USERS, result)
        } else {
          commit(CONST.SET_USERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_USERS_ERRORS, error)
      })
  },
  inviteUser({ commit }, payload) {
    return this._vm.$axios
      .post('/orgs/invite', payload)
      .then(({ response }) => {
        const { success, error } = response.data

        if (!success && error) {
          commit(CONST.SET_USERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_USERS_ERRORS, error)
      })
  },
  fetchUserById({ commit }, userId = '') {
    return this._vm.$axios
      .get(`/users/${userId}`)
      .then(() => {})
      .catch(error => {
        commit(CONST.SET_USERS_ERRORS, error)
      })
  },
  fetchUsers({ commit }) {
    return this._vm.$axios
      .get(`/users`)
      .then(response => {
        const { result, success, error } = response.data
        if (success) {
          commit(CONST.SET_USERS, result)
        } else {
          commit(CONST.SET_USERS_ERRORS, error)
        }
      })
      .catch(error => {
        commit(CONST.SET_USERS_ERRORS, error)
      })
  },
  updateUserInvitation(vuexContext, payload) {
    return this._vm.$axios
      .put(`/users/${payload.userId}`, payload.body)
      .then(res => console.log(res))
      .catch(error => console.error(error))
  },
  async updateUserSettings(_, payload) {
    await this._vm.$axios.post('/users/me/settings', payload)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
