import * as CONST from '../mutations/WorkOrder'
import WorkOrderService from '../../services/WorkOrders'
const state = {
  workOrders: [],
  workOrderById: {},
  errors: [],
}

const getters = {
  getWorkOrders: state => state.workOrders || [],
  getWorkOrderById: state => state.workOrderById || {},
  getErrors: state => state.errors || [],
}

const mutations = {
  [CONST.SET_ALL_WORK_ORDERS]: (state, workOrders) => {
    state.workOrders = workOrders
  },
  [CONST.SET_WORK_ORDER_BY_ID]: (state, workOrder) => {
    state.workOrderById = workOrder
  },
  [CONST.SET_WORK_ORDER_ERRORS]: (state, errors = []) => {
    state.errors = errors
  },
}

const actions = {
  async fetchAllWorkOrders({ commit }) {
    let { type, data } = await WorkOrderService.getAllWorkOrders()
    if (type === 'success') {
      commit(CONST.SET_ALL_WORK_ORDERS, data)
    } else {
      commit(CONST.SET_WORK_ORDER_ERRORS, data)
    }
  },
  async fetchWorkOrderByID({ commit }, id) {
    if (!id) {
      return
    }
    const response = await WorkOrderService.getWorkOrderById(id)

    if (response.type == 'success') {
      commit(CONST.SET_WORK_ORDER_BY_ID, response.data)
    }

    if (response.type == 'error') {
      commit(CONST.SET_WORK_ORDER_ERRORS, response.data)
    }
  },
  async createWorkOrder({ commit }, data) {
    if (!data) {
      throw new Error('Work Order Data is Required')
    }

    const response = await WorkOrderService.createWorkOrder(data)

    if (response.type == 'error') {
      commit(CONST.SET_WORK_ORDER_ERRORS, response.data)
    }
  },
  async updateWorkOrder({ commit }, data) {
    if (!data) {
      throw new Error('Work Order Data is Required')
    }

    const response = await WorkOrderService.updateWorkOrder(data)

    if (response.type == 'error') {
      commit(CONST.SET_WORK_ORDER_ERRORS, response.data)
    }
  },
  async deleteWorkOrder({ commit }, id) {
    if (!id) {
      throw new Error('Work Order ID is Required')
    }

    const response = await WorkOrderService.deleteWorkOrder(id)

    if (response.type == 'error') {
      commit(CONST.SET_WORK_ORDER_ERRORS, response.data)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
