import WorkOrderLinkedItemTransformer from '../transformers/WorkOrderLinkedItemTransformer'
import { instance as axios } from '../plugins/axios'
import ListTransformer from '../transformers/Lists'

/**
 *
 * @param {*} itemType
 * @param {*} workOrderID
 * @returns
 */
async function fetchWorkOderUsageItemByByItemType(itemType, workOrderID) {
  return axios
    .get(
      `/workorder_usage_items/by_item_type/${itemType}?workorder_id=${workOrderID}`
    )
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse(
          'success',
          WorkOrderLinkedItemTransformer.transformListItemsArray(result)
        )
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

/**
 *
 * @param {*} id
 * @returns
 */
async function fetchItemByID(id) {
  return axios
    .get(`/workorder_usage_items/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse(
          'success',
          WorkOrderLinkedItemTransformer.transformListItemsArray(result)[0]
        )
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

/**
 *
 * @param {*} data
 * @returns
 */
async function createItem(data) {
  return axios
    .post(`/workorder_usage_items`, data)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result.usage_item_id)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}
/**
 *
 * @param {*} data
 * @returns
 */
async function updateItem(data) {
  return axios
    .put(`/workorder_usage_items/${data.workorder_usage_item_id}`, data)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result.usage_item_id)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

/**
 *
 * @param {*} id
 * @returns
 */
async function deleteItem(id) {
  return axios
    .delete(`/workorder_usage_items/${id}`)
    .then(({ data }) => {
      const { success, error, result } = data

      if (success) {
        return ListTransformer.prepareResponse('success', result.success)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

export default {
  fetchWorkOderUsageItemByByItemType,
  fetchItemByID,
  createItem,
  deleteItem,
  updateItem,
}
