import * as CONST from '../mutations/Reports'
import ReportService from '../../services/Reports'
const state = {
  reports: [],
  errors: [],
  csvReport: [],
}

const getters = {
  reports: state => state.reports || [],
  csvReport: state => state.csvReport || [],
}

const mutations = {
  [CONST.SET_ALL_REPORTS]: (state, reports) => {
    state.reports = reports
  },
  [CONST.SET_REPORTS_ERRORS]: (state, errors = []) => {
    state.errors = errors
  },
  [CONST.SET_CSV_REPORT]: (state, report) => {
    state.csvReport = report
  },
}

const actions = {
  async fetchReports({ commit }) {
    let { type, data } = await ReportService.getAllReports()
    if (type === 'success') {
      commit(CONST.SET_ALL_REPORTS, data)
    } else {
      commit(CONST.SET_REPORTS_ERRORS, data)
    }
  },
  async exportCSV({ commit }, payload) {
    let { type, data } = await ReportService.exportCSV(payload)
    if (type === 'success') {
      commit(CONST.SET_CSV_REPORT, data)
    } else {
      commit(CONST.SET_REPORTS_ERRORS, data)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
