import { instance as axios } from '../plugins/axios'
import ListTransformer from '../transformers/Lists'
/**
 *
 * @param {*} id
 * @returns
 */
async function getAllReports() {
  return await axios
    .get(`/reports`)
    .then(({ data }) => {
      const { success, error, result } = data
      if (success) {
        return ListTransformer.prepareResponse('success', result)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}
async function exportCSV(path) {
  return await axios
    .get(`/reports/${path}`)
    .then(({ data }) => {
      const { success, error, result } = data
      if (success) {
        return ListTransformer.prepareResponse('success', result)
      }

      if (error) {
        return ListTransformer.prepareResponse('error', error)
      }
    })
    .catch(error => {
      return ListTransformer.prepareResponse('error', error)
    })
}

export default {
  getAllReports,
  exportCSV,
}
