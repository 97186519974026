import moment from 'moment'

export const quantity = [
  {
    text: 'None',
    value: null,
  },
  {
    text: 'Every 1',
    value: '1',
  },
  {
    text: 'Every 2',
    value: '2',
  },
  {
    text: 'Every 3',
    value: '3',
  },
  {
    text: 'Every 4',
    value: '4',
  },
  {
    text: 'Every 5',
    value: '5',
  },
  {
    text: 'Every 6',
    value: '6',
  },
  {
    text: 'Every 7',
    value: '7',
  },
]
export const units = [
  {
    text: 'Days',
    value: 'days',
  },
  {
    text: 'Weeks',
    value: 'weeks',
  },
  {
    text: 'Months',
    value: 'months',
  },
  {
    text: 'Years',
    value: 'years',
  },
]
export const fromOptions = [
  {
    text: 'From Previous Due Date',
    value: 'due',
  },
  {
    text: 'From Previous Completion Date',
    value: 'completed',
  },
]
export const visibilityQuantity = [
  {
    text: 'On Due Date',
    value: null,
  },
  {
    text: '1',
    value: '1',
  },
  {
    text: '2',
    value: '2',
  },
  {
    text: '3',
    value: '3',
  },
  {
    text: '4',
    value: '4',
  },
  {
    text: '5',
    value: '5',
  },
  {
    text: '6',
    value: '6',
  },
  {
    text: '7',
    value: '7',
  },
]
export const visibilityUnits = [
  {
    text: 'Days Before Due Date',
    value: 'days',
  },
  {
    text: 'Weeks Before Due Date',
    value: 'weeks',
  },
  {
    text: 'Months Before Due Date',
    value: 'months',
  },
  {
    text: 'Years Before Due Date',
    value: 'years',
  },
]
export const roles = [
  {
    title: 'Asset Manager',
    detail:
      'Able to create, update, and delete Assets. Able to create and update Asset Types. This role is not required to view asset information or to complete work.',
  },
  {
    title: 'User Manager',
    detail: 'Able to invite new users and manage settings for existing users.',
  },
  {
    title: 'Work Manager',
    detail:
      'Able to create and modify work orders and maintenance tasks.  This role is not required to complete assigned work.',
  },
]

export const assetManager = 'Asset Manager'
export const workManager = 'Work Manager'
export const userManager = 'User Manager'
export const orgAdmin = 'Org Admin'
export const token = 'token'
export const inOrg = 'inOrg'

export const tableFields = [
  {
    key: 'name',
    label: 'Name',
    formatter: (value, key, item) => {
      return `${item.last_name}, ${item.first_name}`
    },
  },
]
export const invitationTableFields = [
  'email',
  {
    key: 'created_on',
    label: 'Invited',
    formatter: (value, key, item) => {
      return moment(item.created_on).format('MM/DD/YYYY')
    },
  },
]

export const prioritiesForWorkOrders = [
  {
    text: 'High',
    value: 3,
  },
  {
    text: 'Normal',
    value: 2,
  },
  {
    text: 'Low',
    value: 1,
  },
]

export const customFieldsType = [
  {
    text: 'Text',
    value: 'text',
  },
  {
    text: 'Number',
    value: 'number',
  },
  {
    text: 'Yes/No',
    value: 'bool',
  },
  {
    text: 'Date',
    value: 'date',
  },
]
