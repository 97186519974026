import moment from 'moment'
export const getPath = pathArray => {
  const { folder_path_array } = pathArray
  if (!folder_path_array || !folder_path_array.length) return []
  return folder_path_array.map((folderPath, index) => ({
    id: folderPath.id,
    name: folderPath.name,
    disable: index === folder_path_array.length - 1,
  }))
}

export const formatDate = date => {
  return moment(date).format('MM/DD/YYYY')
}

export const formatDateAndTime = date => {
  return moment(date).format('MM/DD/YYYY hh:mm A')
}

export const scrollToBottom = container => {
  if (!container) {
    return
  }
  window.scroll(0, container.scrollHeight)
}
