export const SET_WORK_ORDERS = 'SET_WORK_ORDERS'
export const SET_WORK_ORDERS_BY_ID = 'SET_WORK_ORDERS_BY_ID'
export const RESET_WORK_ORDERS_BY_ID = 'RESET_WORK_ORDERS_BY_ID'
export const SET_WORK_ORDERS_ERRORS = 'SET_WORK_ORDERS_ERRORS'
export const SET_WORK_SELECTED_ASSET = 'SET_WORK_SELECTED_ASSET'
export const SET_IS_MINE_WORKORDERS = 'SET_IS_MINE_WORKORDERS'
export const SET_WORK_ORDER_NOTES = 'SET_WORK_ORDER_NOTES'
export const SET_ASSET_WORK_ORDERS = 'SET_ASSET_WORK_ORDERS'
export const SET_WORK_ORDERS_META = 'SET_WORK_ORDERS_META'
export const RESET_WORK_ORDERS = 'RESET_WORK_ORDERS'
export const SET_WORKORDER_FILES = 'SET_WORKORDER_FILES'
export const SET_WORK_ORDERS_USAGE_ITEM_BY_TYPE =
  'SET_WORK_ORDERS_USAGE_ITEM_BY_TYPE'
export const SET_SELECTED_WORK_ORDER_USAGE_ITEM_DATA =
  'SET_SELECTED_WORK_ORDER_USAGE_ITEM_DATA'
