function transformListItem(data) {
  return {
    workOrderUsageItemID: data.workorder_usage_item_id,
    workOrderID: data.workorder_id,
    usageItemID: data.usage_item_id,
    type: data.usage_item_name,
    note: data.note,
    quantity: data.quantity,
    date: data.usage_on,
  }
}

function transformListItemsArray(data) {
  return data.map(transformListItem)
}

export default {
  transformListItem,
  transformListItemsArray,
}
