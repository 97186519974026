import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/views/errors/NotFound'

import globals from '@/globals'

import Public from '@/layout/Public'
import Private from '@/layout/Private'

Vue.use(Router)
Vue.use(Meta)
const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem('token')) {
    next()
    return
  }
  next({
    name: 'login',
    params: {
      returnTo: to.path,
      query: to.query,
    },
  })
}
const checkTokenAndNavigate = (to, from, next) => {
  if (localStorage.getItem('token')) {
    next({
      name: 'assets',
    })
    return
  }
  next()
}
const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Public,
      redirect: '/login',
      beforeEnter: checkTokenAndNavigate,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () =>
            import(/* webpackChunkName: "login" */ '@/views/auth/Login'),
        },
        {
          path: '/new-account',
          name: 'register',
          component: () =>
            import(/* webpackChunkName: "register" */ '@/views/auth/Register'),
        },
        {
          path: '/confirm/:code',
          name: 'confirm',
          component: () =>
            import(/* webpackChunkName: "confirm" */ '@/views/auth/Confirm'),
        },
        {
          path: '/forgot-password',
          name: 'forgotPassword',
          component: () =>
            import(
              /* webpackChunkName: "forgotPassword" */
              '@/views/auth/ForgotPassword'
            ),
        },
        {
          path: '/password-reset/:code',
          name: 'resetPassword',
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */
              '@/views/auth/ResetPassword'
            ),
        },
      ],
    },
    {
      path: '/organization',
      name: 'organization',
      component: () =>
        import(
          /* webpackChunkName: "organizations" */
          '@/views/organizations/Organizations'
        ),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/join-organization',
      name: 'joinOrganizations',
      component: () =>
        import(
          /* webpackChunkName: "organizations" */
          '../views/organizations/JoinOrganization.vue'
        ),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/new-organization',
      name: 'createOrganization',
      component: () =>
        import(
          /* webpackChunkName: "createOrganization" */
          '@/views/organizations/NewOrganization'
        ),
    },
    {
      path: '/',
      component: Private,
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: '/',
          name: 'home',
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */
              '@/views/assets/home'
            ),
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */
              '@/views/assets/dashboard.vue'
            ),
        },
        {
          path: 'map',
          name: 'map',
          component: () =>
            import(/* webpackChunkName: "map" */ '@/views/map/Map'),
        },
        {
          path: 'map2',
          name: 'map2',
          component: () =>
            import(/* webpackChunkName: "map2" */ '@/views/map/Map2'),
        },
        {
          path: 'assets',
          name: 'assets',
          component: () =>
            import(/* webpackChunkName: "assets" */ '@/views/assets/Assets'),
        },
        {
          path: 'folders/:id',
          name: 'folderDetails',
          component: () =>
            import(
              /* webpackChunkName: "folderDetails" */ '@/views/folders/index'
            ),
        },
        {
          path: 'asset/:id?',
          name: 'upsertAsset',
          component: () =>
            import(
              /* webpackChunkName: "upsertAsset" */
              '@/views/assets/UpsertAsset'
            ),
        },
        {
          path: 'asset/alt/:alternateId',
          name: 'alternateAsset',
          component: () =>
            import(
              /* webpackChunkName: "AlternateAsset" */
              '@/views/assets/AlternateAsset'
            ),
        },
        {
          path: 'list-asset-types',
          name: 'assetTypes',
          component: () =>
            import(
              /* webpackChunkName: "assetTypes" */
              '@/views/assets/AssetTypes'
            ),
        },
        {
          path: 'workorders',
          name: 'workOrders',
          component: () =>
            import(
              /* webpackChunkName: "workorders" */
              '@/views/workorders/Workorders.vue'
            ),
        },
        {
          path: 'upsert-workorder',
          name: 'createWorkOrder',
          component: () =>
            import(
              /* webpackChunkName: "createWorkOrder" */
              '../views/workorders/UpsertWorkOrder.vue'
            ),
        },
        {
          path: 'workorders/:id',
          name: 'upsertWorkOrder',
          component: () =>
            import(
              /* webpackChunkName: "upsertWorkOrder" */
              '@/views/workorders/UpsertWorkOrder.vue'
            ),
        },
        {
          path: '/maintenance',
          name: 'maintenance',
          component: () =>
            import(
              /* webpackChunkName: "UpsertWorkOrder" */
              '../views/maintenance'
            ),
        },
        {
          path: '/maintenance-task',
          name: 'maintenance-task',
          component: () =>
            import(
              /* webpackChunkName: "maintenanceTask" */
              '../views/maintenance/maintenanceTask.vue'
            ),
        },
        {
          path: '/maintenance-details/:id?',
          name: 'maintenance details',
          component: () =>
            import(
              /* webpackChunkName: "maintenance Details" */
              '../views/maintenance/details'
            ),
        },
        {
          path: 'settings',
          name: 'settings',
          component: () =>
            import(
              /* webpackChunkName: "settings" */
              '@/views/settings/index.vue'
            ),
        },
        {
          // to create and edit asset types
          path: 'asset-types/:id?',
          name: 'upsertAssetTypes',
          component: () =>
            import(
              /* webpackChunkName: "upsertAssetTypes" */
              '@/views/assets/UpsertAssetType'
            ),
        },
        {
          path: 'logout',
          name: 'logout',
          component: () =>
            import(/* webpackChunkName: "logut" */ '@/views/auth/Logout'),
        },
        {
          path: 'lists',
          name: 'lists',
          component: () =>
            import(/* webpackChunkName: "lists" */ '@/views/lists/Lists'),
        },
        {
          path: 'reports',
          name: 'reports',
          component: () =>
            import(/* webpackChunkName: "reports" */ '@/views/reports/Reports'),
        },
        {
          path: 'configuration',
          name: 'configuration',
          component: () =>
            import(
              /* webpackChunkName: "configuration" */ '@/views/configuration/Configuration'
            ),
        },
        {
          path: '/esri',
          name: 'Esri',
          component: () =>
            import(/* webpackChunkName: "esri" */ '@/views/esri'),
        },
      ],
    },
    {
      // 404 Not Found page
      path: '*',
      component: NotFound,
    },
  ],
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
